<template>
  <div class="banner pt-32 pb-32 pt-md-72 pb-md-72">
    <div class="container d-flex flex-column align-items-center">
      <div
        class="row d-flex justify-content-center align-items-center mb-48 mb-md-64"
      >
        <h2 class="mb-0 text-center">
          {{ $label('common.howitworks.home.title') }}
        </h2>
      </div>

      <div class="row">
        <HowItWorksBlock
          :title="$label('common.howitworks.home.firstBlock.title')"
          :text="$label('common.howitworks.home.firstBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.firstBlock.btnText')"
          :on-button-click="() => scrollTo('products')"
        >
          <svg
            width="145"
            height="152"
            viewBox="0 0 145 152"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M126.444 46.3001C137.475 64.9944 145.851 80.774 144.319 97.1566C142.685 113.539 131.245 130.424 113.881 140.978C96.4145 151.43 72.9219 155.652 51.3701 148.315C29.8183 141.078 10.2071 122.283 3.36362 98.8652C-3.58199 75.4471 2.34221 47.4057 18.5827 27.6059C34.721 7.70549 61.2778 -3.8528 81.3997 1.17254C101.522 6.09738 115.311 27.6059 126.444 46.3001Z"
              fill="white"
            />
            <path
              d="M72.5 55C60.9031 55 51.5 64.4031 51.5 76C51.5 87.5969 60.9031 97 72.5 97C84.0969 97 93.5 87.5969 93.5 76C93.5 64.4031 84.0969 55 72.5 55ZM75.4766 72.6156C75.6828 72.6156 75.8516 72.7844 75.8516 72.9906V74.2656C75.8516 74.4719 75.6828 74.6406 75.4766 74.6406H69.1016C69.0875 74.8469 69.0875 75.0672 69.0875 75.2875V76.975H75.4719C75.6781 76.975 75.8469 77.1437 75.8469 77.35V78.625C75.8469 78.8312 75.6781 79 75.4719 79H69.3547C70.0719 81.9062 72.2281 83.6219 75.4391 83.6219C76.3719 83.6219 77.1781 83.5656 77.8672 83.4297C78.0969 83.3828 78.3125 83.5609 78.3125 83.7953V85.8016C78.3125 85.9797 78.1859 86.1297 78.0125 86.1672C77.2672 86.3266 76.4047 86.4062 75.4203 86.4062C70.2734 86.4062 66.8422 83.65 66.0359 79H64.625C64.4187 79 64.25 78.8312 64.25 78.625V77.35C64.25 77.1437 64.4187 76.975 64.625 76.975H65.8484V75.2453C65.8484 75.0391 65.8484 74.8328 65.8625 74.6453H64.625C64.4187 74.6453 64.25 74.4766 64.25 74.2703V72.9953C64.25 72.7891 64.4187 72.6203 64.625 72.6203H66.1109C67.0344 68.2047 70.4234 65.5938 75.4203 65.5938C76.4 65.5938 77.2672 65.6828 78.0125 65.8469C78.1859 65.8844 78.3078 66.0344 78.3078 66.2125V68.2188H78.3125C78.3125 68.4578 78.0969 68.6312 77.8625 68.5844C77.1734 68.4484 76.3719 68.3781 75.4391 68.3781C72.3734 68.3781 70.2641 69.9484 69.4578 72.6156H75.4766Z"
              fill="#263465"
            />
          </svg>
        </HowItWorksBlock>

        <HowItWorksBlock
          :title="$label('common.howitworks.home.secondBlock.title')"
          :text="$label('common.howitworks.home.secondBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.secondBlock.btnText')"
          :on-button-click="() => scrollTo('books-carousel')"
        >
          <svg
            width="128"
            height="136"
            viewBox="0 0 128 136"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M115.422 35.1294C127.26 54.3583 131.239 75.8669 125.223 92.1222C119.304 108.378 103.39 119.281 86.6998 126.913C69.9127 134.545 52.3494 139.005 36.0474 133.653C19.7455 128.399 4.60805 113.333 0.920719 95.9878C-2.76662 78.543 4.9962 58.7195 17.5137 39.3915C29.9342 19.9643 47.1095 1.03282 65.5461 0.0416367C83.9828 -0.949543 103.681 15.9005 115.422 35.1294Z"
              fill="white"
            />
            <path
              d="M59 47V87H67V47H59ZM67 52.3333L77.6667 87L85.6667 84.3333L75 49.6667L67 52.3333ZM48.3333 52.3333V87H56.3333V52.3333H48.3333ZM43 89.6667V95H91V89.6667H43Z"
              fill="#263465"
            />
          </svg>
        </HowItWorksBlock>

        <HowItWorksBlock
          :title="$label('common.howitworks.home.thirdBlock.title')"
          :text="$label('common.howitworks.home.thirdBlock.bodyTxt')"
          :button-label="$label('common.howitworks.home.thirdBlock.btnText')"
          :on-button-click="() => $router.push('boeken')"
        >
          <svg
            width="129"
            height="144"
            viewBox="0 0 129 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M119.662 23.2756C132.181 40.7844 129.762 66.9975 122.503 90.009C115.244 113.021 103.146 133.031 85.5781 140.434C67.9047 147.938 44.8662 142.836 28.9812 132.23C13.0962 121.625 4.57507 105.417 1.62951 89.1086C-1.31605 72.9005 1.41911 56.4922 10.7818 39.5837C20.2497 22.7753 36.4503 5.26653 58.1212 1.06442C79.8974 -3.23774 107.249 5.76679 119.662 23.2756Z"
              fill="white"
            />
            <path
              d="M72.5 74C72.5 73.7667 72.425 73.575 72.275 73.425C72.125 73.275 71.9333 73.2 71.7 73.2H66.1V64.4C66.1 64.1833 66.0208 63.9958 65.8625 63.8375C65.7042 63.6792 65.5167 63.6 65.3 63.6H60.5C60.2833 63.6 60.0958 63.6792 59.9375 63.8375C59.7792 63.9958 59.7 64.1833 59.7 64.4V73.2H54.1C53.8833 73.2 53.6958 73.2792 53.5375 73.4375C53.3792 73.5958 53.3 73.7833 53.3 74C53.3 74.2333 53.375 74.425 53.525 74.575L62.325 83.375C62.475 83.525 62.6667 83.6 62.9 83.6C63.1333 83.6 63.325 83.525 63.475 83.375L72.25 74.6C72.4167 74.4 72.5 74.2 72.5 74ZM88.5 79.6C88.5 82.25 87.5625 84.5125 85.6875 86.3875C83.8125 88.2625 81.55 89.2 78.9 89.2H51.7C48.6167 89.2 45.9792 88.1042 43.7875 85.9125C41.5958 83.7208 40.5 81.0833 40.5 78C40.5 75.8333 41.0833 73.8333 42.25 72C43.4167 70.1667 44.9833 68.7917 46.95 67.875C46.9167 67.375 46.9 67.0167 46.9 66.8C46.9 63.2667 48.15 60.25 50.65 57.75C53.15 55.25 56.1667 54 59.7 54C62.3 54 64.6792 54.725 66.8375 56.175C68.9958 57.625 70.5667 59.55 71.55 61.95C72.7333 60.9167 74.1167 60.4 75.7 60.4C77.4667 60.4 78.975 61.025 80.225 62.275C81.475 63.525 82.1 65.0333 82.1 66.8C82.1 68.0667 81.7583 69.2167 81.075 70.25C83.2417 70.7667 85.0208 71.8958 86.4125 73.6375C87.8042 75.3792 88.5 77.3667 88.5 79.6Z"
              fill="#263465"
            />
          </svg>
        </HowItWorksBlock>
      </div>
    </div>
  </div>
</template>

<script>
import HowItWorksBlock from '~/components/howItWorks/HowItWorksBlock'
import { scrollIntoView } from '~/utils'

export default {
  components: {
    HowItWorksBlock,
  },
  methods: {
    scrollTo(selector) {
      const el = document.querySelector(`[scroll-id=${selector}]`)

      scrollIntoView(
        el,
        {
          behavior: 'smooth',
        },
        30,
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background-color: $grey-50;
}

@include media-breakpoint-down(sm) {
  .how-it-works__block {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}

@include media-breakpoint-up(md) {
  .row {
    width: 100%;
  }
}

.col {
  flex: 1;
}
</style>
