<script setup>
import { useStore } from 'vuex'
import HomepageBanner from '~/components/banners/HomepageBanner'
import BecomeMemberBanner from '~/components/banners/BecomeMemberBanner'
import USPsList from '~/components/USPs/USPsList'
import SubscriptionProducts from '~/components/subscriptions/SubscriptionProducts'
import AppBanner from '~/components/banners/AppBanner'
import PremiumBanner from '~/components/basicSubscription/PremiumBanner'

useHead({
  meta: [
    {
      name: 'facebook-domain-verification',
      content: '6eqp0l7fvwdjglwdjjwb73wwp5dilx',
    },
  ],
})

const { $sentryCaptureException } = useNuxtApp()
const { query } = useRoute()
const store = useStore()
const loggedInUser = computed(() => store.getters.loggedInUser)
const canBuySubscription = computed(() => store.getters.canBuySubscription)
const canUpgradeToPremium = computed(() => store.getters.canUpgradeToPremium)
const hasAutoRenewalSubscription = computed(
  () => store.getters.hasAutoRenewalSubscription,
)

const { data: products, error } = await useAsyncData(() =>
  store.dispatch('subscriptions/getProducts'),
)

if (error.value) {
  $sentryCaptureException(error.value)
}

if (process.server && hasAutoRenewalSubscription.value) {
  await navigateTo({ path: 'boeken', query })
}
</script>

<template>
  <section data-testid="homepage">
    <HomepageBanner :can-buy-subscription="canBuySubscription" />

    <HowItWorks v-if="canBuySubscription" />

    <div class="mt-32 mt-md-72" scroll-id="books-carousel">
      <BooksCarousel show-categories />
    </div>

    <div v-if="products && canBuySubscription" scroll-id="products">
      <SubscriptionProducts
        :products="products"
        with-background
        :title="$label('subscription.chooseProduct.title')"
        :subtitle="$label('subscription.chooseProduct.subtitle')"
        pre-selected-btn-label="subscription.info.preSelectedBtn"
        select-btn-label="subscription.info.selectBtn"
      />
    </div>
    <PremiumBanner v-else-if="canUpgradeToPremium" />
    <AppBanner v-else />

    <USPsList />

    <div
      v-if="canBuySubscription"
      :class="{ 'hide-footer-divider': loggedInUser }"
    >
      <BecomeMemberBanner />
    </div>

    <div
      v-if="canUpgradeToPremium && !canBuySubscription"
      class="hide-footer-divider"
    >
      <AppBanner />
    </div>

    <HappinessGuarantee v-if="!loggedInUser" />
  </section>
</template>

<style lang="scss" scoped>
.hide-footer-divider {
  margin-bottom: -41px;
  position: relative;
  z-index: 2;
}
</style>
